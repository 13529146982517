/* eslint-disable */
import jsb from 'node-jsb';

jsb.prefix_regexp = /jsb_([^\s]+)/g;
jsb.awaitRegex = /[\S]+_await$/;

jsb.applyBehaviour = (parent_dom_element, applyToAwaitBehaviour) => {
    var dom_elements = jsb.getJsbElementsInDomElement(parent_dom_element);
    var dom_elements_length = dom_elements.length;
    var dom_element = null;
    var class_match = null;
    var current_match = null;
    var class_match_length = 0;
    var class_match_tracker = 0;
    var is_await_handler = false;
    var behaviour_name = null;

    if (typeof applyToAwaitBehaviour === 'undefined') {
        applyToAwaitBehaviour = false;
    }

    for (var i = 0; i < dom_elements_length; i++) {
        dom_element = dom_elements[i];

        class_match = dom_element.className.match(jsb.prefix_regexp);
        class_match_length = class_match.length;
        class_match_tracker = 0;

        for (var j = 0; j < class_match_length; j+=1) {
            current_match = class_match[j].substring(4);
            behaviour_name = current_match;
            is_await_handler = jsb.awaitRegex.test(current_match);

            if ((!is_await_handler) || applyToAwaitBehaviour) {
                if (is_await_handler) {
                    behaviour_name = current_match.substring(0, behaviour_name.length - 6);
                }

                class_match_tracker += 1;
                jsb.callHandler(behaviour_name, dom_element);
                jsb.removeClassFromElement(dom_element, jsb.prefix + current_match);
            }
        }

        if (class_match_tracker === class_match_length) {
            jsb.removeClassFromElement(dom_element, jsb.prefix);
        }
    }

    jsb.fireEvent('Jsb::BEHAVIOURS_APPLIED');
};

jsb.callHandler = (key, dom_element) => {
    if (typeof jsb.handlers[key] === 'undefined') {
        /* Hide require from webpack. It needs this fix. */
        var need = window["require"];
        if (typeof need === 'undefined') {
            throw new Error('The handler ' + key + ' is not defined!');
        } else {
            need([key], function(require_result) {
                if (typeof jsb.handlers[key] === 'undefined') {
                    if (typeof require_result === 'undefined') {
                        throw new Error('The handler ' + key + ' is not defined (even with requirejs)!');
                    } else {
                        jsb.registerHandler(key, require_result);
                    }
                }
                jsb.callHandler(key, dom_element);
            });
            return ;
        }
    }

    var value_string = null;
    var dashed_key_name = key.toString().replace(/\//g, '-');

    if (dom_element.getAttribute('data-jsb-' + dashed_key_name)) {
        /*
         * Nice, we have a class specific data-jsb attribute -> let's use that one!
         */
        value_string = dom_element.getAttribute('data-jsb-' + dashed_key_name);
    } else if (dom_element.getAttribute('data-jsb')) {
        /*
         * Nice, we have a data-jsb attribute -> let's use that one!
         */
        value_string = dom_element.getAttribute('data-jsb');
    }

    jsb.handleJsbHandlers(jsb.handlers[key], dom_element, value_string);
};

jsb.handleJsbHandlers = (jsb_handler, dom_element, value_string) => {
    var options = value_string !== null ? jsb.parseValueString(value_string): undefined;
    var result = new jsb_handler(dom_element, options);

    if (typeof result.then === 'function') {
        result.then(object => {
            try {
                new object.default(dom_element, options);
            } catch (error) {
                console.error(error);
            }
        });
    }
};

export default jsb;
