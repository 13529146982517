import $ from 'jquery';

import { Foundation } from 'foundation-sites/js/foundation.core';
import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu';
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { Sticky } from 'foundation-sites/js/foundation.sticky';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import { Toggler } from 'foundation-sites/js/foundation.toggler';
import { Accordion } from 'foundation-sites/js/foundation.accordion';

class FoundationLoader {
    constructor() {
        this.initializeFoundation();
    }

    initializeFoundation() {
        // connect foundation and jquery first
        Foundation.addToJquery($);

        // add global foundation modules here
        Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');
        Foundation.plugin(AccordionMenu, 'AccordionMenu');
        Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
        Foundation.plugin(DropdownMenu, 'DropdownMenu');
        Foundation.plugin(OffCanvas, 'OffCanvas');
        Foundation.plugin(Reveal, 'Reveal');
        Foundation.plugin(Sticky, 'Sticky');
        Foundation.plugin(Dropdown, 'Dropdown');
        Foundation.plugin(Toggler, 'Toggler');
        Foundation.plugin(Accordion, 'Accordion');

        // finally initialize foundation
        $(document).foundation();
    }
}

export default FoundationLoader;
