import jsb from '../modules/ForkNodeJsb';

const Timeline = () => import(/* webpackChunkName: "Timeline" */ './Timeline.jsb');
const Subscribe = () => import(/* webpackChunkName: "Subscribe" */ './Subscribe.jsb');
const Langswitcher = () => import(/* webpackChunkName: "Langswitcher" */ './Langswitcher.jsb');

const jsbModules = {
    Timeline,
    Subscribe,
    Langswitcher
};

Object.keys(jsbModules).forEach((jsbModuleName) => {
    jsb.registerHandler(jsbModuleName, jsbModules[jsbModuleName]);
});
